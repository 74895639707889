define([], function() {
  

  var ScriptOnComplete = function() {

    var self = this;

    var write = function(scriptName) {
      var span = document.createElement('span');
      span.setAttribute(self._dataAttribute, scriptName);
      document.body.appendChild(span);
    };

    self._dataAttribute = 'data-script-complete';
    self.write = write;
  };

  var soc = new ScriptOnComplete();
  return soc;
});
